//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import { useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { signIn, signOut } from '../redux/actions/auth';
import { doneChecking } from '../redux/actions/session';
import API from '../api/API';

// check every every 10 seconds
const INTERVAL = 10000;

function Loader({loggedIn, signInF, signOutF, doneCheckingF}) {

	const shouldBeLoggedIn = () => {
		const session = window.localStorage.getItem('session');
		return session && session.length > 0;
	};

	useLayoutEffect(() => {
		const checkLogin = (() => {
			if(!loggedIn && shouldBeLoggedIn()){
				API.authorize().then(({data}) => {
					signInF(data);
					// let the router know we are done checking the session and logged in to the account
					doneCheckingF();
				}).catch(error => {
					console.log(error);
					signOutF();
					// let the router know we are done checking the session and cannot be logged in to the account
					doneCheckingF();
				});
			}else {
				// let the router know we are done checking the session and NOT logged in to the account
				doneCheckingF();
			}
		})();
		// set the interval for checking loggedIn status
		setInterval(checkLogin, INTERVAL);
	}, [
		doneCheckingF,
		loggedIn,
		signOutF,
		signInF
	]);
	return null;
}

const mapStateToProps = state => {
	return {
		loggedIn: state.accountState.loggedIn
	};
};

const mapDispatchToProps = dispatch => {
	return {
		signInF: account => dispatch(signIn(account)),
		signOutF: () => dispatch(signOut()),
		doneCheckingF: () => dispatch(doneChecking())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
