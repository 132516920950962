//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import React, { Suspense } from 'react';
import {
	Redirect,
	Switch,
	Route
} from 'react-router-dom';
import PropTypes from 'prop-types';

import EmptyContainer from '../App/EmptyContainer';
import { connect } from 'react-redux';

import MyNavbar from '../App/MyNavbar';
import MyHeader from '../App/MyHeader';
import Footer from './Footer';

const PrivacyPolicy = React.lazy(() => import('../../containers/PrivacyPolicy'));
const SignIn = React.lazy(() => import('../../containers/SignIn'));
const SignUp = React.lazy(() => import('../../containers/SignUp'));
const EditAccount = React.lazy(() => import('../../containers/EditAccount'));
const ChangePassword = React.lazy(() => import('../../containers/ChangePassword'));
const SubAccounts = React.lazy(() => import('../../containers/SubAccounts'));
const NewSubAccount = React.lazy(() => import('../../containers/NewSubAccount'));
const EditSubAccount = React.lazy(() => import('../../containers/EditSubAccount'));
const CreatePaymentlink = React.lazy(() => import('../../containers/CreatePaymentlink'));
const PaymentLinks = React.lazy(() => import('../../containers/PaymentLinks'));
const Apikey = React.lazy(() => import('../../containers/Apikey'));
const CreateApikey = React.lazy(() => import('../../containers/CreateApikey'));

function MySwitch({ account, loggedIn, checkingSession }) {
	const withNormalStyle = (WrappedComponent) =>
		<Suspense fallback={<EmptyContainer/>}>
			<div className="App-container d-flex flex-column">
				<MyNavbar />
				<MyHeader />
				<WrappedComponent />
				<Footer loggedIn={loggedIn} />
			</div>
		</Suspense>
	;

	// with login or noLogin, wait until the session check is done
	const withLoader = (component, authRestr = true, noSubAllowed=false, roleReq='') => {
		if(checkingSession){
			return withNormalStyle(EmptyContainer);
		}
		// show component or redirect to '/' according to the authentication restriction
		if(loggedIn === authRestr){
			// if sub-accounts are not allowed and the account is a sub-account, don't allow it
			if(noSubAllowed && account.roles.find(role => role === 'subaccount')){
				return <Redirect to="/" />;
			}

			// if there is a role requirement and the account does not have it, don't allow it
			if(roleReq && !account.roles.find(role => role === roleReq)){
				return <Redirect to="/" />;
			}

			return withNormalStyle(component);
		}
		return <Redirect to="/" />;
	};

	return (
		<Switch>
			<Route path="/privacy">
				{withNormalStyle(PrivacyPolicy)}
			</Route>

			{/* AUTHENTICATED ROUTES */}
			<Route path="/account">
				{withLoader(EditAccount)}
			</Route>
			<Route path="/change-password">
				{withLoader(ChangePassword)}
			</Route>
			<Route path="/subaccounts" exact>
				{withLoader(SubAccounts)}
			</Route>
			<Route path="/subaccounts/new" exact>
				{withLoader(NewSubAccount)}
			</Route>
			<Route path="/subaccounts/edit/:username">
				{withLoader(EditSubAccount, true, true)}
			</Route>
			<Route path="/create">
				{withLoader(CreatePaymentlink, true)}
			</Route>
			<Route path="/paymentlinks">
				{withLoader(PaymentLinks, true)}
			</Route>
			<Route path="/apikeys">
				{withLoader(Apikey, true)}
			</Route>
			<Route path="/apikey/new">
				{withLoader(CreateApikey, true)}
			</Route>

			<Route path='/api-docs/' component={() => { 
				window.location.href = `https://${window.location.host}/api-docs/`; 
				return null;
			}}/>

			{/* NON-AUTHENTICATED ROUTES */}
			<Route path="/sign-in">
				{withLoader(SignIn, false)}
			</Route>
			<Route path="/sign-up">
				{withLoader(SignUp, false)}
			</Route>
			<Route path="/" render={() => <Redirect to={loggedIn? '/create' : '/sign-in'} />}/>
		</Switch>
	);
}

MySwitch.propTypes = {
	loggedIn: PropTypes.bool,
	checkingSession: PropTypes.bool
};

const mapStateToProps = state => {
	return {
		account: state.accountState.account,
		loggedIn: state.accountState.loggedIn,
		checkingSession: state.sessionState.checkingSession
	};
};

export default connect(mapStateToProps)(MySwitch);
