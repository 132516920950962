//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

// create an empty form object (otherwise we get an uncontrolled input error)
const emptyForm = {
	company: '',
	vat: '',
	firstName: '',
	lastName: '',
	merchantId: '',
	hmacKey: '',
	address: ['', '', ''],
	postalCode: '',
	city: '',
	country: 'NL',
	email: '',
	phone: '',
	username: '',
	password: '',
	confirmPassword: ''
};

export default emptyForm;
