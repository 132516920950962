//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import styled, {css} from 'styled-components';
import { Button } from 'reactstrap';

const styling = css`
  color: ${props => props.txtclr.data} !important;
  border-color: ${props => props.txtclr.data} !important;
  background-color: ${props => props.btnclr.data} !important;
`;

const StyledButton = styled(Button)`${styling}`;

export default StyledButton;
