//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

// import action type
import { SIGNIN, SIGNOUT } from '../action-types';

const setLSToLoggedIn = () => {
	// set the stringified object
	window.localStorage.setItem('session', '1');
};

const setLSToLoggedOut = () => {
	// set the stringified object
	window.localStorage.setItem('session', '');
};

// import function with action and payload attribute
export const signIn = (payload) => {
	// set localstorage key with token and loggedIn to true
	setLSToLoggedIn();
	return {
		type: SIGNIN,
		payload
	};
};

// import function with action and payload attribute
export const signOut = () => {
	// set localstorage key with no token and loggedIn to false
	setLSToLoggedOut();
	return {
		type: SIGNOUT
	};
};
