//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import MyAxios from './MyAxios';

export default {

	createSubAccount: subAccount => MyAxios.post('/accounts/subaccounts', subAccount),
  
	getSubAccounts: () => MyAxios.get('/accounts/subaccounts'),

	getSubAccount: username => MyAxios.get(`/accounts/subaccounts/${encodeURIComponent(username)}`),

	updateSubAccount: (username, subAccount) => MyAxios.put(`/accounts/subaccounts/${encodeURIComponent(username)}`, subAccount),
  
	deleteSubAccount: username => MyAxios.delete(`/accounts/subaccounts/${encodeURIComponent(username)}`)

};
