//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import React from 'react';
import { Container, Col, Row } from 'reactstrap';

function Footer() {
	return (
		<Col xs="auto" className="justify-content-center align-items-center p-0">
			<Row className="App-footer justify-content-center align-items-center p-4">
				<Container>
					<Row>
						<Col sm="4" className="d-flex flex-column justify-content-center align-items-center">
							<Col xs="auto">
								<p className="effra-text pb-4">
									<u>Rotterdam</u><br/>
									Brainpoint Betaalsystemen bv<br/>
									Linatebaan 71 B<br/>
									3045 AH ROTTERDAM<br/>
									Nederland
								</p>
								<p className="effra-text pb-4">
									E-mail: <a href="mailto:info@brainpoint.nl" className="my-link">info@brainpoint.nl</a><br/>
									Telefoon: +31 (0)10 262 33 03
								</p>
							</Col>
						</Col>
						<Col sm="4" className="d-flex flex-column justify-content-center align-items-center">
							<Col xs="auto">
								<p className="effra-text pb-4">
									<u>Balie in Schiphol Plaza</u><br/>
									Brainpoint Betaalsystemen bv<br/>
									Aankomstpassage 17<br/>
									1118 AX AMSTERDAM<br/>
									Nederland
								</p>
								<p className="effra-text pb-4">
									E-mail: <a href="mailto:info@brainpoint.nl" className="my-link">info@brainpoint.nl</a><br/>
									Telefoon: +31 (0)20 653 09 97
								</p>
							</Col>
						</Col>
						<Col sm="4" className="d-flex flex-column justify-content-center align-items-center">
							<Col xs="auto">
								<p className="effra-text pb-4">
									<u>Brussel</u><br/>
									Brainpoint Betaalsystemen bvba<br/>
									Excelsiorlaan 5<br/>
									1930 ZAVENTEM / BRUSSEL<br/>
									België
								</p>
								<p className="effra-text pb-4">
									E-mail: <a href="mailto:info@brainpoint.be" className="my-link">info@brainpoint.be</a><br/>
									Telefoon: +32 (0)2 223 7760
								</p>
							</Col>
						</Col>
					</Row>
					<Row>
						<Col className="pt-2">
							<p className="text-center">© 2021 Brainpoint Betaalsystemen bv. Alle rechten voorbehouden.</p>
						</Col>
					</Row>
				</Container>
			</Row>
		</Col>
	);
}

export default Footer;
