//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

// import action type
import { DONECHECKING } from '../action-types';

// import function with action and payload attribute
export const doneChecking = () => {
	return {
		type: DONECHECKING
	};
};
