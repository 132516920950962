//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';

import './App.scss';

// styling per screen part is tucked away in components
import MySwitch from '../src/components/App/MySwitch';
import Loader from './containers/Loader';

function App() {
	return (
		<Router>
			<Loader />

			<MySwitch />
		</Router>
	);
}

export default App;
