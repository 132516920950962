//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import MyAxios from './MyAxios';

export default {

	// create a payment link
	createPaymentLink: (orderId, description, amount, language) => {
		const multipleUse = true; //indicator if the link can be reused for muliple transactions
		return MyAxios.post(
			'/paymentlinks',
			{ orderId, description, amount, language, multipleUse }
		);
	},

	// retrieve all payment links of a specific merchant
	getPaymentLinks: () => MyAxios.get('/paymentlinks'),

	// retrieve the status of the payment link
	getPaymentLinkStatus: (merchantId, date) => MyAxios.get(`/paymentlinks/${merchantId}/${date}`),

};
