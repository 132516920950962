//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import { SIGNIN, SIGNOUT, UPDATEACCOUNT, SETNEWACCOUNT } from '../action-types'; 
import EMPTYFORM from '../../vars/emptyForm';

// create an initial state
const initialState = {
	account: {
		info: EMPTYFORM
	},
	loggedIn: false,
	newAccount: false
};

// create the reducer
const accountReducer = (state = initialState, action) => {
	// update the account
	if (action.type === SETNEWACCOUNT){
		return { ...state, newAccount: true };
	} 

	// update the account
	if (action.type === UPDATEACCOUNT){
		return { ...state, account: action.payload };
	} 

	// login the account
	if (action.type === SIGNIN){
		return { ...state, account: action.payload, loggedIn: true};
	} 
	
	// set account back to initialState
	if (action.type === SIGNOUT){
		return { ...state, ...initialState};
	}

	// initialize the initial state
	return state;
};

export default accountReducer;
