//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import { DONECHECKING } from '../action-types'; 

// create an initial state
const initialState = {
	checkingSession: true
};

// create the reducer
const sessionReducer = (state = initialState, action) => {
	// update the account
	if (action.type === DONECHECKING){
		return { checkingSession: false };
	} 
	// initialize the initial state
	return state;
};

export default sessionReducer;
