//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

// AUTH
export const SIGNIN = 'signIn';
export const SIGNOUT = 'signOut';

// ACCOUNT
export const UPDATEACCOUNT = 'updateAccount';
export const SETNEWACCOUNT = 'setNewAccount';

// SESSION
export const DONECHECKING = 'doneChecking';
