//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import React from 'react';
import { Row } from 'reactstrap';

import '../../css/Header.scss';

function MyHeader() {
	return <Row className="my-header"/>;
}

export default MyHeader;
