//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import { combineReducers } from 'redux';

// import all the reducers
import accountReducer from './account-reducer';
import sessionReducer from './session-reducer';

// create the reducers object
const rootReducer = combineReducers({
	accountState: accountReducer,
	sessionState: sessionReducer 
});

// export the combine-reducer function, to apply middleware, not need for now
// const mergeReducers = extraReducers => combineReducers({...reducers, ...extraReducers});

export default rootReducer;
