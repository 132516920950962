//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import MyAxios from './MyAxios';

export default {

	// create an account with the given credentials
	createAccount: account => {
		return MyAxios.post(
			'/accounts',
			account
		);
	},

	// get the account information with the set cookie
	getAccount: () => MyAxios.get('/accounts'),

	// update an account with the given credentials
	updateAccount: account => {
		return MyAxios.put(
			'/accounts',
			account
		);
	},

	// log in with the given credentials
	login: (username, password, token) => {
		return MyAxios.post(
			'/auth/login',
			{
				username,
				password,
				token
			}
		);
	},

	// logout, cookies are reset by backend
	logout: () => MyAxios.post('/auth/logout'),

	// authorize with the cookies set by account_api
	authorize: () => MyAxios.get('/auth/me'),

	// get the sub accounts
	getSubAccounts: () => MyAxios.get('/accounts/subaccounts'),

	// delete a sub account
	deleteSubAccount: username => MyAxios.delete(`/accounts/subaccounts/${encodeURIComponent(username)}`),

	// initialize 2fa activation
	activate2fa: () => MyAxios.post('/auth/2fa'),

	// activate 2fa
	send2faToken: token => MyAxios.post('/auth/2fa', {token}),

	// activate 2fa
	disable2fa: token => MyAxios.post('/auth/2fa/disable', {token}),

};
