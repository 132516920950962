//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import React from 'react';
import { Container } from 'reactstrap';

function EmptyContainer() {
	return (
		<Container className="flex-grow-1 justify-content-start align-items-center"/>
	);
}

export default EmptyContainer;
