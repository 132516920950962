//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import axios from 'axios';

import OPTIONS from '../vars/api';

export default axios.create({
	baseURL: process.env.NODE_ENV === 'development'? '' : '/api',
	timeout: OPTIONS.RESPONSETIMEOUT,
	withCredentials: true
});
