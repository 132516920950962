//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import MyAxios from './MyAxios';

export default {

	createApikey: (description, expiryDate) => MyAxios.post('/api-keys', {description, expiryDate}),
  
	getApikeys: () => MyAxios.get('/api-keys'),

	getApikey: keyid => MyAxios.get(`/api-keys/${encodeURIComponent(keyid)}`),
  
	updateApikey: (keyid, revoked) => MyAxios.put(`/api-keys/${encodeURIComponent(keyid)}`, {revoked}),	

	deleteApikey: keyid => MyAxios.delete(`/api-keys/${encodeURIComponent(keyid)}`)

};
