//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

import React, { useState } from 'react';
import {
	Container,
	Row,
	Col,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
	Button
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { signOut } from '../../redux/actions/auth';
import API from '../../api/API';

import '../../css/Navbar.scss';
import StyledButton from '../Styled/StyledButton';

function MyNavbar(props) {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const handleSignOut = () => {
		API.logout().then(() => {
			props.signOut();
		}).catch(error => {
			console.log(error);
		});
	};

	const LogoButton = () =>
		<Col xs="auto" className="d-flex flex-column justify-content-center align-items-center">
			<a href="/"></a>
		</Col>
	;

	const NewSecretButton = () =>
		<Row xs="auto" className="justify-content-end align-items-center d-none d-md-inline">
			<Link to="/">
				<StyledButton
					txtclr="#4485bc"
					btnclr="#FFFFFF">
						Nieuwe betaallink
				</StyledButton>
			</Link>
		</Row>
	;

	const RightButtons = () =>
		<Row className="justify-content-end align-items-center">
			{
				props.loggedIn? <NewSecretButton /> : null
			}
			{
				props.loggedIn?
					<React.Fragment>
						<Dropdown className="m-0" isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
							<DropdownToggle
								className="account-button"
								caret
							>
								Hallo, {props.account.firstName}
							</DropdownToggle>

							<DropdownMenu>
								{
									props.account.roles.find(role => role === 'subaccount')?
										null
										:
										<Link to="/account"className="discover-link"> <DropdownItem > Account </DropdownItem> </Link>
								}
								{
									props.account.roles.find(role => role === 'subaccount')?
										null
										:
										<Link to="/apikeys" className="discover-link"> <DropdownItem> API keys </DropdownItem> </Link>
								}
								{
									props.account.roles.find(role => role === 'subaccount')?
										null
										:
										<Link to="/paymentlinks" className="discover-link"> <DropdownItem> Betaallinks </DropdownItem> </Link>
								}
								<Link to="/change-password" className="discover-link"> <DropdownItem> Verander wachtwoord </DropdownItem> </Link>
							</DropdownMenu>
						</Dropdown>

						<Button
							onClick={handleSignOut}
							className="account-button"
						><b>Uitloggen</b></Button>
					</React.Fragment>
					:
					<Link to="/sign-in">
						<Button
							className="account-button"
							onClick={() => props.history.push('/sign-in')}
						><b>Inloggen</b></Button>
					</Link>
			}
		</Row>
	;

	return (
		<Row className="justify-content-center align-items-center navbar-desktop">
			<Container>
				<Row className="navbar-desktop">
					<LogoButton />
					<RightButtons/>
				</Row>
			</Container>
		</Row>
	);
}

const mapStateToProps = state => {
	return {
		loggedIn: state.accountState.loggedIn,
		account: state.accountState.account
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		signOut: () => dispatch(signOut())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyNavbar));
