//Copyright © 2021 Cloudcontainers b.v. - All Rights Reserved

// Import the calls for the API
import axiosAccount from './Account';
import axiosSubAccount from './SubAccount';
import axiosPaymentLink from './PaymentLink';
import axiosApikeys from './Apikeys';

export default {
	// account calls,
	...axiosAccount,

	// subAccount calls,
	...axiosSubAccount,

	// paymentlink calls
	...axiosPaymentLink,

	// apikey calls
	...axiosApikeys
};
